// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #f0f0f0; /* Heller Hintergrund für das gesamte Dokument */
  font-family: 'Roboto', sans-serif; /* Eine moderne Schriftart verwenden */
  margin: 0; /* Entferne Standardmargin */
  padding: 0; /* Entferne Standardpadding */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,gDAAgD;EAC3E,iCAAiC,EAAE,sCAAsC;EACzE,SAAS,EAAE,4BAA4B;EACvC,UAAU,EAAE,6BAA6B;AAC3C","sourcesContent":["body {\n  background-color: #f0f0f0; /* Heller Hintergrund für das gesamte Dokument */\n  font-family: 'Roboto', sans-serif; /* Eine moderne Schriftart verwenden */\n  margin: 0; /* Entferne Standardmargin */\n  padding: 0; /* Entferne Standardpadding */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
