import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
    return (
        <Box textAlign="center" py={{ base: 2, md: 4 }} bg="teal.500" color="black"> {/* Responsive Padding */}
            <Text fontSize={{ base: 'sm', md: 'md' }}> {/* Responsive Font Size */}
                © 2024 Mein Name. Alle Rechte vorbehalten.
            </Text>
        </Box>
    );
};

export default Footer;
