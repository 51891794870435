import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Footer from './components/Footer';

function App() {
    return (
        <Box maxW="1200px" mx="auto">
            <Header />
            <About />
            <Projects />
            <Footer />
        </Box>
    );
}

export default App;
