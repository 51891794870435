import React from 'react';
import { Box, Text, Image, VStack } from '@chakra-ui/react';
import myPhoto from '../my-photo.jpg'; // Dein Bild importieren

const About = () => {
    return (
        <Box p={{ base: 4, md: 6 }} bg="white" borderRadius="lg" shadow="lg" margin="20px">
            <VStack spacing={4}>
                <Image 
                    borderRadius="full" 
                    boxSize="150px" 
                    src={myPhoto} 
                    alt="Mein Foto" 
                />
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">Über mich</Text>
                <Text>
                    Ich bin [Dein Name], ein leidenschaftlicher [dein Beruf, z.B. Webentwickler], der sich auf [deine Spezialisierung, z.B. Frontend-Entwicklung] spezialisiert hat. 
                    Ich liebe es, kreative und funktionale Lösungen zu entwickeln und bin immer auf der Suche nach neuen Herausforderungen.
                </Text>
                <Text fontWeight="bold">Kontaktdaten:</Text>
                <Text>E-Mail: dein.email@example.com</Text>
                <Text>Telefon: +49 123 456789</Text>
            </VStack>
        </Box>
    );
};

export default About;

