import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const Header = () => {
    return (
        <Box bg="teal.500" p={{ base: 2, md: 4 }} shadow="md">  {/* Responsive Padding */}
            <Heading color="black" textAlign="center" fontSize={{ base: 'xl', md: '2xl' }}> {/* Responsive Font Size */}
                Mein Portfolio
            </Heading>
        </Box>
    );
};

export default Header;
