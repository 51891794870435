import React from 'react';
import { Box, Text, List, ListItem } from '@chakra-ui/react';

const Projects = () => {
    return (
        <Box p={{ base: 4, md: 6 }} bg="white" borderRadius="md" shadow="md" margin="20px">
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold">Meine Projekte</Text> {/* Responsive Font Size */}
            <List spacing={3}>
                <ListItem>Projekt 1: Beschreibung</ListItem>
                <ListItem>Projekt 2: Beschreibung</ListItem>
                <ListItem>Projekt 3: Beschreibung</ListItem>
            </List>
        </Box>
    );
};

export default Projects;
